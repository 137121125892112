'use client';

import { createContext, useContext } from 'react';
//
import { WebPushContextProps } from './types';

// ----------------------------------------------------------------------

export const WebPushContext = createContext({} as WebPushContextProps);

export const usePushContext = () => {
  const context = useContext(WebPushContext);

  if (!context) throw new Error('useWebpushContext must be use inside WebPushProvider');

  return context;
};
