import { createSlice } from '@reduxjs/toolkit';
import { ErrorStateProps } from '@/types/error';
import { ErrorResponse } from '@/app/api/types';

const initialState: ErrorStateProps = {
  isError: false,
  code: '',
  status: 500,
  message: '',
};

const slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state, action: { payload: ErrorResponse }) {
      state.isError = true;
      state.code = action.payload.code;
      state.status = action.payload.status || 500;
      state.message = action.payload.message;
    },
    clearError(state) {
      state.isError = false;
      state.code = '';
      state.status = 500;
      state.message = '';
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setError, clearError } = slice.actions;

// Selector
export const errorSelector = (state: { error: ErrorStateProps }) => state.error;
