import { createSlice } from '@reduxjs/toolkit';
import { Product, ProductStateProps } from '@/types/products';
import { Category } from '@/types/categories';

const initialState: ProductStateProps = {
  selectedCategory: null,
  products: [],
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setSelectedCategory(state, action: { payload: Category | null }) {
      state.selectedCategory = action.payload;
    },
    setProducts(state, action: { payload: Product[] }) {
      state.products = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedCategory, setProducts } = slice.actions;

// Selector
export const productSelector = (state: { product: ProductStateProps }) => state.product;
