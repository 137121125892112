import { useDebugValue, useEffect, useRef, useState } from 'react';
import { browserPWACompatility } from '@/shared/config-global';
import { detectBrowserName } from '@/utils/browser';
import { getPWADisplayMode } from '@/utils/display';
import Cookies from 'js-cookie';

/**
 * Handle installing website as a PWA
 */
export function usePWAInstall({ enable, cookieName }: { enable: boolean; cookieName: string }) {
  const beforeInstallPromptEvent = useRef<BeforeInstallPromptEvent | undefined>();
  const userChoice = useRef<'dismissed' | 'accepted' | undefined>();
  const mounted = useRef(false);
  const browserName = detectBrowserName();
  const displayMode = getPWADisplayMode();
  const isBrowserPWACompatiblity = browserPWACompatility[browserName];

  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  useDebugValue({ enable });

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  async function installPWA() {
    if (isBrowserPWACompatiblity) {
      if (!beforeInstallPromptEvent.current) {
        throw new Error('must wait for before install prompt event');
      }

      beforeInstallPromptEvent.current.prompt();
      const { outcome } = await beforeInstallPromptEvent.current.userChoice;

      if (!mounted.current) return;

      hideInstallPrompt(outcome === 'accepted');

      beforeInstallPromptEvent.current = undefined;
    } else {
      hideInstallPrompt(true);
    }
  }

  function hideInstallPrompt(accepted: boolean) {
    setShowInstallPrompt(false);
    beforeInstallPromptEvent.current = undefined;
    if (!accepted) {
      Cookies.set(cookieName, '1', { expires: 1 }); // 1 day
    } else {
      Cookies.remove(cookieName);
    }
  }

  useEffect(() => {
    if (!enable || displayMode === 'standalone') return;

    function onAppInstalled() {
      // TODO - send to analytics
    }

    function onBeforeInstallPrompt(e?: Event) {
      if (e) {
        e.preventDefault();

        // Stash the event so it can be triggered later.
        beforeInstallPromptEvent.current = e as BeforeInstallPromptEvent;
        // guard: sometimes this event fires when user clicks "cancel" in chrome ui (linux)
        if (!userChoice.current) {
          if (!Cookies.get(cookieName)) {
            setShowInstallPrompt(true);
          }
        }
      } else if (!Cookies.get(cookieName)) {
        setShowInstallPrompt(true);
      }
    }

    if (isBrowserPWACompatiblity) {
      window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);
      window.addEventListener('appinstalled', onAppInstalled);
    } else {
      onBeforeInstallPrompt();
    }

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
      window.removeEventListener('appinstalled', onAppInstalled);
    };
  }, [enable, cookieName, displayMode, isBrowserPWACompatiblity]);

  return [showInstallPrompt, installPWA, hideInstallPrompt] as const;
}
