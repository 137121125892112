'use client';

// layout
import CompactLayout from '@/layouts/compact/layout';
// @mui
import Box from '@mui/material/Box';
// components
import Logo from '@/components/logo';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

export default function MaintenanceView() {
  return (
    <CompactLayout enabledBackground>
      <Logo text disabledLink sx={{ my: 5 }} />

      <Box flexGrow={1} />

      <Box>
        <Typography variant="h3" paragraph sx={{ color: 'white' }}>
          Sitio web actualmente en mantenimiento
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          ¡Actualmente estamos trabajando duro en esta página!
        </Typography>
      </Box>

      <Box flexGrow={1} />
    </CompactLayout>
  );
}
