'use client';

import { m } from 'framer-motion';
import { useRouter } from 'next/navigation';
// @mui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// layouts
import CompactLayout from 'src/layouts/compact';
// components
import { varBounce } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function View403() {
  const { back } = useRouter();

  return (
    <CompactLayout>
      <Box flexGrow={0.7} />
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          Sin autorización
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          La página a la que intentas acceder tiene acceso restringido.
          <br />
          Consulte al administrador de su sistema.
        </Typography>
      </m.div>

      <Box flexGrow={1} />

      <Button size="large" variant="contained" color="inherit" onClick={() => back()}>
        Ir a casa
      </Button>
    </CompactLayout>
  );
}
