// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// utils
import { detectDevice } from '@/utils/device';
import { pwaInstallTitle } from '@/utils';
// config
import { browserPWACompatility } from '@/shared/config-global';
import { detectBrowserName } from '@/utils/browser';
//
import PWANotification from './pwa-notification';

// ----------------------------------------------------------------------

export default function InstallBanner({
  onCancel,
  onOk,
  show,
}: {
  onCancel: () => void;
  onOk: () => void;
  show: boolean;
}) {
  const deviceName = detectDevice();
  const browserName = detectBrowserName();
  const isBrowserPWACompatiblity = browserPWACompatility[browserName];
  const isMobile = deviceName === 'android' || deviceName === 'ios';

  return (
    <Box sx={{ backgroundColor: 'red' }}>
      {isMobile && (
        <PWANotification
          onCancel={onCancel}
          onOk={onOk}
          show={show}
          title={pwaInstallTitle(deviceName, isBrowserPWACompatiblity)}
          okText={isBrowserPWACompatiblity ? 'Instalar' : undefined}
        >
          <Typography variant="caption">
            Agregar/instalar Intelli Market a la pantalla de inicio proporciona una forma rápida de
            iniciarlo.
          </Typography>
        </PWANotification>
      )}
    </Box>
  );
}
