import { IconButtonProps } from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function IconButton(theme: Theme) {
  const rootStyles = (ownerState: IconButtonProps) => {
    const edgeEnd = ownerState.edge === 'end';

    return {
      ...(edgeEnd && {
        marginRight: -8,
      }),
    };
  };

  return {
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: IconButtonProps }) => rootStyles(ownerState),
      },
    },
  };
}
