'use client';

// import { DisplayModeNotification } from '@/components/pwa/display-mode-notification';
import { PWAInstallBanner } from '@/components/pwa/pwa-prompt';
import { usePWAInstall } from '@/hooks/use-pwa-install';
import { cookies, enabledPWAInstallerBanner } from '@/shared/config-global';

// ----------------------------------------------------------------------

export default function PWAOperator() {
  const [showInstallPrompt, installPWA, hideInstallPrompt] = usePWAInstall({
    enable: enabledPWAInstallerBanner,
    cookieName: cookies.pwaInstallDismissed.name,
  });
  // const isSSL = window.location.protocol.includes('https');

  return (
    <>
      {/* {!isSSL && <DisplayModeNotification />} */}

      <PWAInstallBanner
        onCancel={() => hideInstallPrompt(false)}
        onOk={installPWA}
        show={showInstallPrompt}
      />
    </>
  );
}
