import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { Coupon, CouponStateProps } from '@/types/coupons';
import { LoyaltyBalance } from '@/types/loyalty-balance';
import { getLoyaltyBalance } from '@/services/loyalty-balance';

const initialState: CouponStateProps = {
  coupons: [],
  loyalty: null,
};

const slice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    setCoupons(state, action: { payload: Coupon[] }) {
      state.coupons = action.payload;
    },
    setLoyalty(state, action: { payload: LoyaltyBalance }) {
      state.loyalty = action.payload;
    },
    addCoupon(state, action: { payload: Coupon }) {
      state.coupons.push(action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setCoupons, addCoupon, setLoyalty } = slice.actions;

// Selector
export const couponSelector = (state: { coupon: CouponStateProps }) => state.coupon;

// Thunk actions
export const getLoyaltyInitial = () => async (dispatch: Dispatch) => {
  try {
    const response = await getLoyaltyBalance();
    dispatch(slice.actions.setLoyalty(response));
  } catch (error) {
    console.error(error);
  }
};
