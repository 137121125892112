import { forwardRef, ReactNode } from 'react';
import { m } from 'framer-motion';
// @mui
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
// components
import Iconify from '@/components/iconify';

// ----------------------------------------------------------------------

const PWANotification = forwardRef<
  HTMLDivElement,
  {
    onCancel: () => void;
    onOk: () => void;
    okText?: string;
    title: string;
    show: boolean;
    children: ReactNode;
  }
>(({ show, okText, children, onCancel, onOk, title }, ref) => (
  <Card
    component={m.div}
    initial={{ opacity: 0, x: '100%' }}
    animate={show ? { opacity: 1, x: 0, transition: { delay: 5 } } : { opacity: 0, x: '100%' }}
    exit={{ opacity: 0, x: '100%' }}
    transition={{ ease: 'easeOut', duration: 0.32 }}
    ref={ref}
    sx={{
      position: 'fixed',
      width: 'calc(100% - 24px)',
      margin: 1.5,
      top: 0,
      zIndex: 1101,
      bgcolor: (theme) => theme.palette.grey[900],
      color: '#FFF',
      padding: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      overflow: 'visible',
    }}
  >
    <Box sx={{ position: 'absolute', top: -8, left: -8 }}>
      <Fab
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          background: (theme) => `${theme.palette.grey[700]} !important`,
          padding: 0.1,
          width: 20,
          height: 20,
          minHeight: 0,
        }}
      >
        <Iconify icon="iconamoon:close" />
      </Fab>
    </Box>
    <Stack direction="row" sx={{ pr: 1 }}>
      <Box>
        <Iconify icon="line-md:download-loop" width={50} />
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        {title ? <Typography variant="subtitle2">{title}</Typography> : null}
        {children}
      </Box>
    </Stack>
    <Button
      sx={{
        mt: 1,
        bgcolor: (theme) => theme.palette.common.white,
        color: (theme) => theme.palette.grey[900],
      }}
      onClick={onOk}
      fullWidth
      color="inherit"
      variant="contained"
      size="small"
      startIcon={
        okText ? (
          <Iconify icon="ic:round-install-desktop" />
        ) : (
          <Iconify icon="lets-icons:done-ring-round" />
        )
      }
    >
      {okText || 'Hecho!'}
    </Button>
  </Card>
));

export default PWANotification;
