'use client';

import { useEffect, useReducer, useCallback, useMemo } from 'react';
//
import { internalApi } from '@/utils/api';
// server actions
import { getAuthUser, postLogin } from '@/services/auth';
// redux
import { useDispatch } from 'react-redux';
import { setConfiguration } from '@/redux/slices/configurations';
// types
import { AuthUser, User } from '@/types/users';
import { Configuration } from '@/types/configurations';
import { updateProfilePicture, updateUsers } from '@/services/users';

import { AuthContext } from './auth-context';
import {
  // getSession,
  setSession,
} from './utils';
import { ActionMapType, AuthStateType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  UPDATE = 'UPDATE',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUser;
  };
  [Types.LOGIN]: {
    user: AuthUser;
  };
  [Types.REGISTER]: {
    user: AuthUser;
  };
  [Types.UPDATE]: {
    user: AuthUser;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.UPDATE) {
    return {
      ...state,
      user: {
        ...(state.user! || {}),
        ...action.payload.user!,
      },
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  initialUser: AuthUser;
  initialConfiguration: Configuration | null;
  children: React.ReactNode;
};

export function AuthProvider({ initialUser, initialConfiguration, children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchRedux = useDispatch();

  const initialize = useCallback(() => {
    dispatchRedux(setConfiguration(initialConfiguration));
    dispatch({
      type: Types.INITIAL,
      payload: {
        user: initialUser,
      },
    });
  }, [dispatchRedux, initialConfiguration, initialUser]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const { authToken: accessToken } = await postLogin(email, password);
    setSession(accessToken);
    const user = await getAuthUser();

    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
      },
    });

    // window.location.href = PATH_AFTER_LOGIN;
  }, []);

  // Forgot password
  const forgotPassword = useCallback(
    async (phone: string, phone_country_code: string) =>
      internalApi.get('/auth/OTP-code-sms', {
        params: {
          phone,
          phone_country_code,
        },
      }),
    []
  );

  const forgotPasswordEmail = useCallback(
    async (email: string) =>
      internalApi.get('/auth/OTP-code-email', {
        params: {
          email,
        },
      }),
    []
  );

  // Token
  const getPasswordChangeToken = useCallback(
    async (code: number, phone: string, phone_country_code: string) =>
      internalApi.post('/auth/OTP-code-sms', {
        params: {
          code,
          phone,
          phone_country_code,
        },
      }),
    []
  );

  const getPasswordChangeTokenEmail = useCallback(
    async (code: number, email: string) =>
      internalApi.post('/auth/OTP-code-email', {
        params: {
          code,
          email,
        },
      }),
    []
  );

  const changePassword = useCallback(
    async (user_id: number, password: string) =>
      internalApi.patch('/auth/change-password', {
        params: {
          users_id: user_id,
          password,
        },
      }),
    []
  );

  // REGISTER
  const register = useCallback(
    (user: Partial<User>) =>
      internalApi.post('/auth/register', {
        params: user,
      }),
    []
  );

  // UPDATE
  const update = useCallback(async (id: number, user: Partial<User>) => {
    const updatedUser = await updateUsers(id, {
      enabled: user.enabled === undefined ? true : user.enabled,
      ...user,
    });

    dispatch({
      type: Types.UPDATE,
      payload: {
        user: updatedUser,
      },
    });
  }, []);

  // UPDATE
  const updatePicture = useCallback(async (image: string) => {
    const updatedUser = await updateProfilePicture(image);

    dispatch({
      type: Types.UPDATE,
      payload: {
        user: updatedUser,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      forgotPassword,
      forgotPasswordEmail,
      getPasswordChangeToken,
      getPasswordChangeTokenEmail,
      changePassword,
      register,
      logout,
      update,
      updatePicture,
    }),
    [
      changePassword,
      forgotPassword,
      forgotPasswordEmail,
      getPasswordChangeToken,
      getPasswordChangeTokenEmail,
      login,
      logout,
      register,
      state.user,
      status,
      update,
      updatePicture,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
