'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';
// utils
import { push_public_key } from '@/shared/config-global';
import { useAuthContext } from '@/auth/hooks';
import { postPushNotificationToken } from '@/services/push-notifications';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { base64ToUint8Array } from './utils';
import { WebPushContext } from './webpush-context';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function WebPushProvider({ children }: Props) {
  const { user } = useAuthContext();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<PushSubscription | null>(null);
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | null>(null);

  const onSubscribe = useCallback(async () => {
    if (!registration) {
      throw new Error('web push not supported');
    }

    try {
      console.log('waiting for subscription...');
      const sub = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: base64ToUint8Array(push_public_key),
      });
      // TODO: you should call your API to save subscription data on server in order to send web push notification from server
      setSubscription(sub);
      setIsSubscribed(true);

      await postPushNotificationToken({
        active: true,
        user_id: user?.id,
        token: {
          subscription: sub,
        },
      });

      console.log('>> [WP] web push subscribed!');
      console.log(sub);
    } catch (error) {
      console.error(error);
    }
  }, [registration, user?.id]);

  const onUnsubscribe = useCallback(async () => {
    if (!subscription) {
      throw new Error('web push not supported');
    }

    await subscription.unsubscribe();
    // TODO: you should call your API to delete or invalidate subscription data on server
    setSubscription(null);
    setIsSubscribed(false);
    console.log('>> [WP] web push unsubscribed!');
  }, [subscription]);

  const invokeServiceWorkerUpdateFlow = useCallback(
    (reg: ServiceWorkerRegistration) => {
      // TODO implement your own UI notification element
      const newDeploySnackbar = enqueueSnackbar(
        'New version of the app is available. Refresh now?',
        {
          variant: 'info',
          autoHideDuration: null,
          action: () => (
            <Button
              onClick={() => {
                if (reg.waiting) {
                  // let waiting Service Worker know it should became active
                  reg.waiting.postMessage('SKIP_WAITING');
                }
                closeSnackbar(newDeploySnackbar);
              }}
            >
              Refresh
            </Button>
          ),
        }
      );
    },
    [closeSnackbar, enqueueSnackbar]
  );

  const initialize = useCallback(async () => {
    // wait for the page to load
    try {
      console.log('[SW] initializing service worker...');

      // register the service worker from the file specified
      const _registration = await navigator.serviceWorker.getRegistration();

      console.log('>> [SW] registration', _registration);

      if (!_registration) throw new Error('Service Worker registration failed');

      // ensure the case when the updatefound event was missed is also handled
      // by re-invoking the prompt when there's a waiting Service Worker
      if (_registration.waiting) {
        console.log('>> [SW] waiting service worker found');
        invokeServiceWorkerUpdateFlow(_registration);
      }

      setRegistration(_registration);

      // initialize push notifications
      const _subscription = await _registration.pushManager.getSubscription();
      console.log('>> [SW] push subscription', _subscription);

      if (
        _subscription &&
        !(_subscription.expirationTime && Date.now() > _subscription.expirationTime - 5 * 60 * 1000)
      ) {
        setSubscription(_subscription);
        setIsSubscribed(true);
      }

      // detect Service Worker update available and wait for it to become installed
      // _registration.addEventListener('updatefound', () => {
      //   console.log('[SW] update found');
      //   console.log('>> [SW] _registration', _registration.installing);

      //   if (_registration.installing) {
      //     // wait until the new Service worker is actually installed (ready to take over)
      //     _registration.installing.addEventListener('statechange', () => {
      //       if (_registration.waiting) {
      //         // if there's an existing controller (previous Service Worker), show the prompt
      //         if (navigator.serviceWorker.controller) {
      //           invokeServiceWorkerUpdateFlow(_registration);
      //         } else {
      //           // otherwise it's the first install, nothing to do
      //           console.log('Service Worker initialized for the first time');
      //         }
      //       }
      //     });
      //   }
      // });

      // let refreshing = false;

      // detect controller change and refresh the page
      // navigator.serviceWorker.addEventListener('controllerchange', () => {
      //   if (!refreshing) {
      //     alert('Refreshing page...');
      //     window.location.reload();
      //     refreshing = true;
      //   }
      // });
    } catch (error) {
      console.error(error);
    }
  }, [invokeServiceWorkerUpdateFlow]);

  useEffect(() => {
    // check if the browser supports serviceWorker at all
    if (!!window && !!navigator.serviceWorker && (window as any).workbox) {
      console.log("[SW] listening for 'load' event");
      // window.addEventListener('load', initialize);
      initialize();
    }

    // return () => {
    //   window.removeEventListener('load', initialize);
    // };
  }, [initialize]);

  const memorizedValues = useMemo(
    () => ({
      isSubscribed,
      onSubscribe,
      onUnsubscribe,
    }),
    [isSubscribed, onSubscribe, onUnsubscribe]
  );

  return <WebPushContext.Provider value={memorizedValues}>{children}</WebPushContext.Provider>;
}
