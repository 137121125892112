export type DeviceName =
  | 'android'
  | 'ios'
  | 'windows_phone'
  | 'windows'
  | 'mac'
  | 'linux'
  | 'unknown';

export const detectDevice = (): DeviceName => {
  const ua = navigator.userAgent.toLowerCase();
  let sDevice: DeviceName = 'unknown';

  if (/android/.test(ua)) {
    sDevice = 'android';
  } else if (/iphone|ipad|ipod/.test(ua)) {
    sDevice = 'ios';
  } else if (/windows phone/.test(ua)) {
    sDevice = 'windows_phone';
  } else if (/windows/.test(ua)) {
    sDevice = 'windows';
  } else if (/mac os/.test(ua)) {
    sDevice = 'mac';
  } else if (/linux/.test(ua)) {
    sDevice = 'linux';
  } else {
    sDevice = 'unknown';
  }

  return sDevice;
};
