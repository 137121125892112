import { DeviceName } from './device';

export const getHrefValueFromString = (content: string) => {
  const contentTag = content;

  const pattern = /href="([^"]+)"/;
  const matches = contentTag.match(pattern);

  if (matches && matches.length >= 2) {
    const contentLink = matches[1];

    return contentLink;
  }

  throw new Error('No href link found.');
};

export const pwaInstallTitle = (
  deviceName: DeviceName,
  isBrowserPWACompatiblity: boolean
): string => {
  if (isBrowserPWACompatiblity) {
    return 'Instalar';
  }
  if (deviceName === 'ios') {
    return 'Elija la flecha hacia arriba Compartir en la parte inferior de la pantalla y seleccione Agregar a la pantalla de inicio.';
  }
  if (deviceName === 'android') {
    return 'Toque el ícono de menú de tres puntos en la esquina superior derecha y seleccione "Agregar a la pantalla de inicio" o "Instalar aplicación".';
  }

  return 'Instalar';
};

export function isSSR() {
  return typeof window === 'undefined';
}
