import { PushNotificationType } from '@/types/push-notification';
import api from '@/utils/api';

export const getPushNotificationToken = (user_id: number): Promise<PushNotificationType> =>
  api.get(`/push_notification_token/${user_id}`);

export const postPushNotificationToken = (pushNotification: Partial<PushNotificationType>) =>
  api.post(`/push_notification_token`, {
    params: pushNotification,
  });
