'use client';

import { usePathActivation } from '@/hooks/use-path-activation';
import { configurationSelector } from '@/redux/slices/configurations';
import { paths } from '@/routes/paths';
import { View403 } from '@/sections/error';
import MaintenanceView from '@/sections/maintenance/maintenance-view';
import { detectDevice } from '@/utils/device';
import { usePathname, useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';

type Props = {
  children: React.ReactNode;
};

export default function ConfigurationProviderClient({ children }: Props) {
  const { configuration } = useSelector(configurationSelector);
  const pathName = usePathname();
  const { replace } = useRouter();
  const { checkPath } = usePathActivation();
  const deviceName = detectDevice();
  const isMobile = deviceName === 'android' || deviceName === 'ios';

  const isAllowed = checkPath(pathName);

  // load maintenance view
  if (configuration?.maintenance) {
    return <MaintenanceView />;
  }

  // go to qr code page when access in desktop
  if (!isMobile && pathName !== paths.landingWeb) replace(paths.landingWeb);

  // go to main page when access in mobile
  if (isMobile && pathName === paths.landingWeb) replace('/');

  if (!isAllowed) {
    return <View403 />;
  }

  return <>{children}</>;
}
